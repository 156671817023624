import axios from "axios";
import {
  GET_CONTACTS,
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  GET_USERS,
  ALL_QA,
} from "../../constants/ActionTypes";
import config from "../../config";
import configureStore from "../store";
export const store = configureStore();

export const getContacts = () => async (dispatch) => {
  const res = await axios.get("/api/v1/user/contact");
  dispatch({
    type: GET_CONTACTS,
    payload: res.data.data,
  });
};
export const getUsers = () => async (dispatch) => {
  const res = await axios.get("/api/v1/user");
  dispatch({
    type: GET_USERS,
    payload: res.data.data,
  });
};
// Get Post

export const getUser = () => async (dispatch) => {
  console.log("This");
  const res = await axios.get(`${config.API_URL}/admin/get/me`);

  dispatch({
    type: GET_USER,
    payload: res.data.data,
  });
};

export const updateUser = (id, data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.put(`/api/v1/user/${id}`, data, config);
  dispatch({ type: UPDATE_USER, payload: res.data.user });
};

export const updateUserpic = (data) => async (dispatch) => {
  const formData = new FormData();
  formData.append("file", data);
  const res = await axios.post(`/api/v1/user/photo`, formData);
  dispatch({ type: UPDATE_USER, payload: res.data.user });
};

export const createUser = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.post("/api/v1/user/register", data, config);
  dispatch({ type: CREATE_USER, payload: res.data });
};

export const setAllQA = (data) => async (dispatch) => {
  const { payload } = dispatch;
  console.log("setAllQA chal gua ====================== dispatch", data);
  // console.log("setAllQA chal gua ======================", payload);
  // const res = await axios.get("/api/v1/user/contact");
  dispatch({
    type: ALL_QA,
    payload: data,
  });
};
