import axios from "axios";
import setAuthToken from "../../util/setAuthToken";
import jwt_decode from "jwt-decode";
import config from "../../config";
import { message } from "antd";
import { getUser } from "./users";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
} from "../../constants/ActionTypes";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(`${config.API_URL}/users/register`, userData)
    .then((res) => history.push("/login")) // re-direct to login on successful register
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Login - get user token
export const loginUser =
  (userData, history, is2FARequired) => async (dispatch) => {
    const res = await axios
      .post(`${config.API_URL}/auth/login`, { ...userData, is2FARequired })
      .then((res) => {
        // Save to localStorage
        // Set token to localStorage

        const { token } = res.data;
        const decoded = jwt_decode(token);
        var pathname = window.location.hostname;
        if (pathname.includes("medship")) {
          if (decoded.company != "65f868c587ab155470418e23") {
            message.error("Your Email Or Password is Wrong");
            return;
          }
        } else if (pathname.includes("femmegen")) {
          if (decoded.company != "65f8692339155254b5d7b36a") {
            message.error("Your Email Or Password is Wrong");
            return;
          }
        } else if (pathname.includes("powderm")) {
          if (decoded.company != "65f868f0d6c9ba548f2a63ab") {
            message.error("Your Email Or Password is Wrong");
            return;
          }
        }

        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data

        // Set current user
        dispatch(setCurrentUser(decoded));
      })
      .catch((error) => {
        message.error(error.response.data.error);
        throw error;
      });
  };
// LoginAs
export const loginAs = (id) => async (dispatch) => {
  try {
    const adtoken = localStorage.jwtToken;
    const res = await axios.get(`${config.API_URL}/auth/loginas/${id}`);
    console.log("res=======login as", res);

    const { token, user } = res.data;
    localStorage.setItem("AdminToken", adtoken);
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);
    const decoded = jwt_decode(token);

    console.log("decoded=======>>", decoded);
    dispatch(setCurrentUser(decoded));

    // Use window.location.href for navigation
    switch (decoded.role) {
      case "doctor":
      case "doctorNurse":
        window.location.href = "/doctor/verification";
        break;
      case "admin":
        window.location.href = "/admin/dashboard";
        break;
      case "practice":
      case "practiceNurse":
        window.location.href = "/practice/verification";
        break;
      case "sale":
        window.location.href = "/sale/dashboard";
        break;
    }
  } catch (error) {
    console.log("Error during login as:", error); // Enhanced logging
    message.error("Error logging in as user");
    dispatch({
      type: GET_ERRORS,
      payload: error.response ? error.response.data : "Network Error",
    });
  }
};

// Verify Two-Factor Authentication
export const verifyTwoFactorAuth = (data, history) => async (dispatch) => {
  try {
    const { code, email } = data;
    console.log("2FA Verification Request:", { code, email });

    const res = await axios.post(`${config.API_URL}/auth/verify-2fa`, {
      values: { otp: code },
    });

    console.log("2FA Verification Response:", res.data);

    if (res.data.success) {
      // Assuming the token is already stored in localStorage by some earlier process
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));

      // Store the last 2FA authentication date
      localStorage.setItem("last2FAAuth", new Date().toISOString());

      // Redirect based on user role
      if (decoded.role === "admin") {
        history.push("/admin/dashboard");
      } else if (decoded.role === "doctor" || decoded.role === "doctorNurse") {
        history.push("/doctor/verification");
      } else if (
        decoded.role === "practice" ||
        decoded.role === "practiceNurse"
      ) {
        history.push("/practice/verification");
      } else {
        history.push("/sale/dashboard");
      }
    } else {
      throw new Error("OTP verification failed");
    }
  } catch (error) {
    console.error(
      "Error during 2FA verification:",
      error.response || error.message
    );
    message.error("Invalid Two-Factor Authentication Code");
    dispatch({
      type: GET_ERRORS,
      payload: error.response ? error.response.data : "Network Error",
    });
  }
};
// Set logged in user
export const setCurrentUser = (decode) => {
  console.log(decode);
  return {
    type: SET_CURRENT_USER,
    payload: decode,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  if (localStorage.AdminToken) {
    localStorage.removeItem("AdminToken");
  }
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location.href = "./login";
};
