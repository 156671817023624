import React from "react";
import { Modal, Button, Row, Col, Divider, Table,Card } from "antd";
import moment from "moment";

class CutomizeFooter extends React.Component {


  state = {
    data: {
      email: "",
      password: "",
      name: "",
      phone: "",
      address: "",
      order: this.props.order,
      orderItems: this.props.order.items
    },
    message: {
      email: "",
    },
    errors: {},
    close: false,
invdata:[]
  };

  columns = [
    {
      title: 'Invoice',
      dataIndex: 'ordernumber',
      key: 'ordernumber',
render:(id,record)=>{
return <span>{record.order.ordernumber} </span>
}
    },
    {
      title: 'Patient',
      dataIndex: 'pfname',
      key: 'pfname',
render:(id,record)=>{
return <span>{record.order.pfname} {record.order.plname}</span>
}
    },
{
      title: 'Sub-Total',
      dataIndex: 'totalamount',
      key: 'hcpcs',
      render: (totalamount,record) => {
       // var cost = record.cost / record.units
console.log(totalamount,record)
if(record.ordernumber){
return (parseFloat(record.totalamount)).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
}else{
        return (record.order.totalamount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
}
    },


 {
      title: 'Credit',
      dataIndex: 'pfname',
      key: 'pfname',
render:(id,record)=>{
if(record.ordernumber){
return (parseFloat(-record.totalamount)).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
}
else{
if(record.subamount || record.subamount=='0'){
return (parseFloat(record.order.totalamount)-parseFloat(record.subamount)).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
}else{
return ''
}

}
}


    },

{
      title: 'Total',
      dataIndex: 'pfname',
      key: 'pfname',
render:(id,record)=>{
console.log(record.subamount)
if(record.subamount || record.subamount=='0'){
return (record.subamount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
}else{
return ''
}

}
    },




  ];
  componentDidMount(){
if(this.props.order.extracredit && this.props.order.extracredit.length>0){
this.setState({invdata:this.props.order.order.concat(this.props.order.extracredit)})
}else{
this.setState({invdata:this.props.order.order})
console.log(this.props.order.order)
}
//this.setState({invdata:this.props.order.order.concat(this.props.order.extracredit)})
    console.log(this.props)
  }
  submit = () => {
    this.props.onClose();
  }
  // createPdf = (html) => Doc.createPdf(html);
  render() {
    const { visible, loading, handleOk, handleCancel } = this.props;
    const { errors, disableButton } = this.state
    return (
        <Card style={{  overflow: 'auto'}}>
        <div width="100%" style={{ padding: 10 }} >
MEDSHIP

   <Row gutter={24} style={{marginTop:"10px"}}>     <Col span={8}>

          <div>Medship</div>
          <div>PO Box 10</div>
          <div>Camden, Ohio 45311</div>
        </Col>
        <Col span={7}></Col>
        <Col span={8}>
        <table>
            <tr>
              <th>Invoice #:</th>
              <td>{this.props.order.multi?<span>c-{this.props.order.invoiceNumber}</span>:this.props.order.invoiceNumber}</td>
            </tr>
            <tr>
              <th>Invoice Date:  </th>
              <td>{" "} {moment(this.props.order.invoiceDate.slice(0,-1)).format(" MM-DD-YYYY")}</td>
            </tr>
   <tr>
              <th>Due upon receipt  </th>
            </tr>
          </table>
        </Col>
        </Row>

      <Row gutter={24} style={{ marginTop: 40 }}>
        <Col span={9}>
          <div>
{console.log(this.props)}
            Bill To: <strong>{this.props.doctor && this.props.doctor.name}</strong>
          </div>
          <div>{this.props.doctor && this.props.doctor.address}</div>
          <div>{this.props.doctor  &&   <span>{this.props.doctor.city}, {this.props.doctor.zipcode} {this.props.doctor.state}</span>}</div>
        </Col>

      </Row>

        <Table
          dataSource={this.state.invdata}
          pagination={false}
          columns={this.columns}
          style={{marginTop:"30px"}}
        >
             </Table>

      <Row style={{ marginTop: 40 }}>
        <Col span={17}>
        </Col>
        <Col span={7}>
<p><strong>
                  <span>Sub-Total: </span>
                  <span >{
                   this.props.order.totalamount && (this.props.order.totalamount).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}</span>
             </strong> </p>
        </Col>

      </Row>
 <Row >
        <Col span={17}>
        </Col>
        <Col span={7}>
<p><strong>
                  <span>Credits: </span>
                  <span >{
                   this.props.order.totalamount && (this.props.order.totalamount-this.props.order.total).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}</span>
             </strong> </p>
        </Col>

      </Row> <Row >
        <Col span={17}>
        </Col>
        <Col span={7}>
<p><strong>
                  <span>Amount Due: </span>
                  <span >{
                    (this.props.order.total).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}</span>
             </strong> </p>
        </Col>

      </Row>
    </div>
    </Card>
    );
  }
}

export default CutomizeFooter;
