import React, { Component } from "react";
import { Layout } from "antd/lib/index";
import { Link } from "react-router-dom";

import Auxiliary from "util/Auxiliary";
import { connect } from "react-redux";
import { toggleCollapsedSideNav } from "../../../appRedux/actions";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
const { Header } = Layout;
import config from "../../../config";
import axios from "axios";

class Topbar extends Component {
  state = {
    practice: "",
  };
  getPractice = () => {
    // const token = localStorage.getItem("token");

    axios
      .get(
        `${config.API_URL}/practice/by-email/${this.props?.auth?.user?.email}`
      )
      .then((response) => {
        console.log(
          "response==============================get Practiec data",
          response.data.data
        );
        localStorage.setItem(
          "accountOnHold",
          response.data.data.accountOnHold
            ? response.data.data.accountOnHold
            : false
        );
        localStorage.setItem(
          "accountInCollection",
          response.data.data.accountInCollection
            ? response.data.data.accountInCollection
            : false
        );
        this.setState({
          doc: response.data.data,
        });
        this.setState({
          practice: response.data.data,
        });
      });
  };

  // componentDidMount() {
  //   this.getAdmin();
  // }

  componentDidMount() {
    this.getPractice();
    console.log(
      "this.props.auth.isAuthenticated======================== in topbar prectiec",
      this.props?.auth?.user?.email
    );
  }

  render() {
    let headerStyle = {};
    if (
      this.state.practice.accountOnHold ||
      this.state.practice.accountInCollection
    ) {
      headerStyle.backgroundColor = "red";
      headerStyle.color = "white";
    }
    // if (this.state.practice.accountInHold) {
    //   headerStyle.backgroundColor = "red";
    //   headerStyle.color = "white";
    // }
    console.log(this.props);
    return (
      <Auxiliary>
        <Header style={headerStyle}>
          <span>
            {this.props.auth &&
              this.props.auth.isAuthenticated &&
              this.props.auth.user.name}
            {(this.state.practice.accountInCollection ||
              this.state.practice.accountOnHold) && (
              <span> - Your Account Is Currently On Hold</span>
            )}
          </span>
          {this.props.setting.width < TAB_SIZE ? (
            <div className="gx-linebar gx-mr-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  this.props.dispatch(
                    toggleCollapsedSideNav(!this.props.setting.navCollapsed)
                  );
                }}
              />
            </div>
          ) : null}

          <Link to="/login" className="gx-d-block gx-d-lg-none gx-pointer">
            MEDSHIP
          </Link>
        </Header>
      </Auxiliary>
    );
  }
}
const mapStateToProps = (state) => ({
  setting: state.setting,
  auth: state.authR,
});

export default connect(mapStateToProps)(Topbar);
