import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import DoctoreRoute from "./DoctoreRoute";
import { connect } from "react-redux";
const TwoFactorRoute = ({ component: Component, auth, ...rest }) => {
  const token = localStorage.getItem("jwtToken");
  const last2FAAuth = localStorage.getItem("last2FAAuth");

  const is2FARequired = () => {
    if (!last2FAAuth) return true;
    const lastAuthDate = new Date(last2FAAuth);
    const currentDate = new Date();
    const diffDays = (currentDate - lastAuthDate) / (1000 * 60 * 60 * 24);
    if (diffDays > 14) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("last2FAAuth");
      window.location.href = "./login";
    }

    return diffDays > 14;
  };

  const decodedToken = token ? jwt_decode(token) : null;
  console.log(decodedToken);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return <Redirect to="/login" />;
        }

        if (
          decodedToken &&
          decodedToken.isTwoFactorEnabled &&
          is2FARequired()
        ) {
          console.log("is2FARequired()", is2FARequired());
          return (
            <Redirect to={`/two-factor-auth?email=${decodedToken.email}`} />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

TwoFactorRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authR,
});
export default connect(mapStateToProps)(TwoFactorRoute);
