import React, { Component } from "react";
import { Modal } from "antd";

class SimpleAlertModal extends Component {
  render() {
    const { isModalOpen, title, children } = this.props;

    return (
      <div>
        <Modal title={title} open={isModalOpen} footer={null}>
          {children}
        </Modal>
      </div>
    );
  }
}

export default SimpleAlertModal;
