import React from "react";
import IdleTimer from "react-idle-timer";

function App() {
  const idleTimerRef = React.useRef(null);

  const onIdle = () => {
    console.log("User has been idle for 30 minutes");
    // Redirect to logout or any other action
    console.log("timeout");
    localStorage.removeItem("jwtToken");
    window.location.href = "/login";
  };

  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={120 * 60 * 1000} // 30 minutes in milliseconds
      onIdle={onIdle}
    >
      <div>{/* Your app components */}</div>
    </IdleTimer>
  );
}

export default App;
