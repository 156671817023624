import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "antd";
import MainApp from "./MainApp";


class App extends Component {
  render() {
    const {match, location, locale, authUser, initURL} = this.props;
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/sample'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }

    return (
      <ConfigProvider>

          <Switch>
               <Route path={`${match.url}`} 
                             component={MainApp}/>
          </Switch>
      </ConfigProvider>
    )
  }
}

export default App;
