import {
  ADD_VERIFICATION_FILTER,
  ADD_ORDERS_FILTER,
  ADD_DEMO_GRAPHS_FILTER,
  ADD_QA_FILTER,
} from "../../constants/ActionTypes";

const initialState = {
  verification: {
    ordernumber: "",
    pfname: "",
    plname: "",
    pmname: "",
    doctor: "",
    surgDate: "",
    dob: "",
    demoQ: "",
    verificationstatus: "",
  },
  orders: {
    ordernumber: "",
    pfname: "",
    plname: "",
    pmname: "",
    insplan: "",
    doctor: "",
    status: "",
    start: "",
    end: "",
    dob: "",
    range: false,
  },
  demoGraphs: {
    demoNumber: "",
    pfname: "",
    plname: "",
    pmname: "",
    doctor: "",
    demoq: "",
    handledby: "",
  },
  qa: {
    ordernumber: "",
    pfname: "",
    plname: "",
    pmname: "",
    doctor: "",
    surgDate: "",
    dob: "",
    demoQ: "",
    verificationstatus: "",
  },
};

export default function verificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_VERIFICATION_FILTER:
      if (!payload || typeof payload !== "object") {
        console.error("Invalid payload for ADD_VERIFICATION_FILTER:", payload);
        return state; // Return the current state if the payload is invalid
      }

      return {
        ...state,
        verification: {
          ...state.verification, // Ensure we are not losing any properties
          ...payload,
        },
      };

    case ADD_ORDERS_FILTER:
      if (!payload || typeof payload !== "object") {
        console.error("Invalid payload for ADD_ORDERS_FILTER:", payload);
        return state; // Return the current state if the payload is invalid
      }

      return {
        ...state,
        orders: {
          ...state.orders, // Ensure we are not losing any properties
          ...payload,
        },
      };

    case ADD_DEMO_GRAPHS_FILTER:
      if (!payload || typeof payload !== "object") {
        console.error("Invalid payload for demoGraphs:", payload);
        return state; // Return the current state if the payload is invalid
      }

      return {
        ...state,
        demoGraphs: {
          ...state.demoGraphs, // Ensure we are not losing any properties
          ...payload,
        },
      };

    case ADD_QA_FILTER:
      if (!payload || typeof payload !== "object") {
        console.error("Invalid payload for QA:", payload);
        return state; // Return the current state if the payload is invalid
      }

      return {
        ...state,
        qa: {
          ...state.qa, // Ensure we are not losing any properties
          ...payload,
        },
      };

    default:
      return state;
  }
}
