import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePrescription,
  faFileInvoiceDollar,
  faReceipt,
  faHandHoldingDollar,
  faUserDoctor,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  render() {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={"gx-sidebar-notifications "}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              className="mnu"
              mode="inline"
            >
              {this.props.authR.user.role == "practice" && (
                <Menu.Item key="dashboard">
                  <Link to="/practice/dashboard">
                    <i className="icon icon-widgets" />
                    <span className="spn">Dashboard</span>
                  </Link>
                </Menu.Item>
              )}
              <MenuItemGroup
                key="order"
                className="gx-menu-group"
                title={<span className="spn">My Orders</span>}
              >
                {this.props.authR.user.role == "practice" && (
                  <Menu.Item key="orders">
                    <Link to="/practice/orders">
                      <FontAwesomeIcon
                        icon={faFilePrescription}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />{" "}
                      <span className="spn">Orders</span>
                    </Link>
                  </Menu.Item>
                )}

                <Menu.Item key="verification">
                  <Link to="/practice/verification">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      style={{
                        color: "#00ac5c",
                        fontSize: "20px",
                        marginRight: "20px",
                      }}
                    />
                    <span className="spn">Verifications</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="demograph">
                  <Link to="/practice/demograph">
                    <FontAwesomeIcon
                      icon={faFilePrescription}
                      style={{
                        color: "#00ac5c",
                        fontSize: "20px",
                        marginRight: "20px",
                      }}
                    />

                    <span className="spn">Demographics</span>
                  </Link>
                </Menu.Item>

                {this.props.authR.user.role == "practice" && (
                  <Menu.Item key="orders">
                    <Link to="/practice/payment">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span className="spn">Invoices</span>
                    </Link>
                  </Menu.Item>
                )}
                {this.props.authR.user.role == "practice" && (
                  <Menu.Item key="credit">
                    <Link to="/practice/credit">
                      <FontAwesomeIcon
                        icon={faHandHoldingDollar}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span className="spn">Credits</span>
                    </Link>
                  </Menu.Item>
                )}

                {this.props.authR.user.role == "practice" && (
                  <Menu.Item key="orders">
                    <Link to="/practice/nurse">
                      <FontAwesomeIcon
                        icon={faUserDoctor}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span className="spn">Administrator</span>
                    </Link>
                  </Menu.Item>
                )}

                {/* {this.props.authR.user.role == "practice" && (
                  <Menu.Item key="orders">
                    <Link to="/practice/add-payment-methods">
                      <FontAwesomeIcon
                        icon={faUserDoctor}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span className="spn">Add Payment Methods</span>
                    </Link>
                  </Menu.Item>
                )} */}
              </MenuItemGroup>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ setting, authR }) => {
  const { pathname } = setting;
  return { pathname, authR };
};
export default connect(mapStateToProps)(SidebarContent);

/*
        <MenuItemGroup key="current" className="gx-menu-group" title={<IntlMessages id="sidebar.current"/>}>
                <Menu.Item key="current/events">
                  <Link to="/user/us/current/events">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.current.events"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="current/calender">
                  <Link to="/user/us/current/calender">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.current.calender"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="profile" className="gx-menu-group" title={<IntlMessages id="sidebar.profile"/>}>
                <Menu.Item key="profile/MyProfile">
                  <Link to="/user/us/profile/MyProfile">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.profile.myprofile"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="profile/EditProfile">
                  <Link to="/user/us/profile/EditProfile">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.profile.edit"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
      */
