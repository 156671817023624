import React, {Component} from "react";
import {Layout} from "antd/lib/index";
import {Link} from "react-router-dom";

import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import {toggleCollapsedSideNav} from "../../../appRedux/actions";
import { TAB_SIZE} from "../../../constants/ThemeSetting";
const {Header} = Layout;

class Topbar extends Component {
render(){
console.log(this.props)
    return (
      <Auxiliary>
        <Header>
    <span>{this.props.auth && this.props.auth.isAuthenticated && this.props.auth.user.name}</span>
        {(this.props.setting.width < TAB_SIZE) ?<div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               this.props.dispatch(toggleCollapsedSideNav(!this.props.setting.navCollapsed));
             }}
          />
        </div> : null}
        
          <Link to="/login" className="gx-d-block gx-d-lg-none gx-pointer">
           MEDSHIP</Link>

        </Header>
      </Auxiliary>
    );
  }

}
const mapStateToProps = state => ({
  setting: state.setting,
  auth: state.authR,

})

export default connect(mapStateToProps)(Topbar);

