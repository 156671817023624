import React,{useEffect} from "react";
import {Button, Checkbox, message,  Input} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import logo from "assets/logo.jpg"
import config from "config";
import axios from "axios";

class Forgot extends React.Component {
    state={
        newpassword:"",
        confirmpassword:"",
    }
 

  componentDidMount() {
  
    console.log("is auth")
    console.log(this.props.auth)
    if (this.props.auth.isAuthenticated) {
   
    if (this.props.auth.user.role == "admin") {
      this.props.history.push('/admin/dashboard');
    }
    else if(this.props.auth.user.role == "doctor") {
   //   console.log(this.props.auth.user)
      this.props.history.push('/doctor/dashboard');
    }
    else {
      this.props.history.push('/sale/dashboard');
    }
  }
  }
  handleOnChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  }
  ResetPassword = e =>{
      console.log(this.props)
      if(this.state.newpassword!=this.state.confirmpassword){
        message.error("Password Not Confirmed")
      }else{
        axios
        .put(`${config.API_URL}/auth/resetpassword/${this.props.match.params.token}`,{password:this.state.confirmpassword})
        .then((response) => {
          if(response.data.success==false){
            message.error(response.data.msg)
    
          }else{
            message.success("Your Password is Changed")
		this.props.history.push("/login")
    
          }
          
        }).catch(error=>{
        message.error(error.response.data.error);
        });

      }
      
  
  }
  

  render() {
    
    return (
      <div className="gx-app-login-wrap" style={{marginTop:"15%"}}>
        <div className="gx-app-login-container" >
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
  {window.location.hostname == "portal.femmegen.health" && (
                  <img
                    src={
                      "https://s3.us-east-2.wasabisys.com/medshipohio/Static/FemmGen-Yellow-Wings.png"
                    }
                    alt="Neature"
                  />
                )}
                {window.location.hostname == "portal.powderm.health" && (
                  <img
                    src={
                      "https://s3.us-east-2.wasabisys.com/medshipohio/Static/powderm.jpg"
                    }
                    alt="Neature"
                  />
                )}
                {window.location.hostname == "portal.medship.health" && (
                  <img src={logo} alt="Neature" />
                )}

              </div>
              <div className="gx-app-logo-wid">

              </div>
             
            </div>

            <div className="gx-app-login-content">
            <div style={{padding:15,marginTop:20}} className='form-group'>
                                            <Input
                                                type='password'
                                                placeholder='Enter New Password'
                                                name='newpassword'
                                                value={this.state.newpassword}
                                                onChange={this.handleOnChange}
                                            />
                                                                                 </div>
                                                                                 <div style={{padding:15}} className='form-group'>
                                            <Input
                                                type='password'
                                                placeholder='Enter Confirm Password'
                                                name='confirmpassword'
                                                value={this.state.confirmpassword}
                                                onChange={this.handleOnChange}
                                            />
                                                                                 </div>
     <div style={{padding:15,marginLeft:70}}>
     <Button type="primary" onClick={this.ResetPassword}>Reset Password</Button>

     </div>

            </div>
     
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.authR
});
export default connect(mapStateToProps)(Forgot);
