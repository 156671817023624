import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import {
  logoutUser,
  setCurrentUser,
} from "../../../appRedux/actions/AuthActions";
import { getUser } from "../../../appRedux/actions/users";
import configureStore, { history } from "../../../appRedux/store";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../../util/setAuthToken";
export const store = configureStore();

class UserProfile extends Component {
  switchback = () => {
    if (localStorage.AdminToken) {
      // Set auth token header auth
      const token = localStorage.AdminToken;
      setAuthToken(token);
      // Decode token and get user info and exp
      const decoded = jwt_decode(token);
      // Set user and isAuthenticated
      localStorage.setItem("jwtToken", token);
      //  localStorage.removeItem()

      store.dispatch(setCurrentUser(decoded));
      //   this.props.history.push("/admin/dashboard"); // push user to dashboard when they login
      localStorage.removeItem("AdminToken");

      window.location.href = "/admin/dashboard";
    }
  };

  componentDidMount() {
    this.props.getUser(this.props.auth.user.id);
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li onClick={() => this.props.logoutUser()}>Logout</li>
        {localStorage.AdminToken && (
          <li onClick={(e) => this.switchback()}>Switch back</li>
        )}
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          {localStorage.AdminToken && (
            <div style={{ color: "white" }}>Impersonating SaleRep</div>
          )}
          <div style={{ color: "white" }}>Logged In As</div>

          <span style={{ color: "white" }} className="gx-avatar-name">
            {this.props.auth && this.props.auth.isAuthenticated
              ? this.props.auth.user.name
              : "SaleRep"}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.authR,
  user: state.users.user,
});

//export default UserProfile
export default connect(mapStateToProps, { getUser, logoutUser })(UserProfile);
