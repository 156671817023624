import {SWITCH_LANGUAGE,TOGGLE_COLLAPSED_NAV,WINDOW_WIDTH} from "constants/ActionTypes";
import {NAV_STYLE} from "../../constants/ThemeSetting";

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}
export function toggleCollapsedSideNav(navCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}

export function updateWindowWidth(width) {
  return {type: WINDOW_WIDTH, width};
}

export function onNavStyleChange(navStyle) {
  return {type: NAV_STYLE, navStyle};
}
