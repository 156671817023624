import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import reducers from "../reducers/index";
import thunk from "redux-thunk";

const createHistory = require("history").createBrowserHistory;

export const history = createHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState = {}) {
  return createStore(
    reducers(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
  );
}
