import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";

import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  render() {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={"gx-sidebar-notifications "}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              className="mnu"
              mode="inline"
            >
              {this.props.authR.user.role == "doctor" && (
                <Menu.Item key="dashboard">
                  <Link to="/doctor/dashboard">
                    <i className="icon icon-widgets" />
                    <span className="spn">Dashboard</span>
                  </Link>
                </Menu.Item>
              )}
              <MenuItemGroup
                key="order"
                className="gx-menu-group"
                title={<span className="spn">My Orders</span>}
              >
                {this.props.authR.user.role == "doctor" && (
                  <Menu.Item key="orders">
                    <Link to="/doctor/orders">
                      <i className="icon icon-orders" />
                      <span className="spn">Orders</span>
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="verification">
                  <Link to="/doctor/verification">
                    <i className="icon icon-orders" />
                    <span className="spn">Verifications</span>
                  </Link>
                </Menu.Item>
                {this.props.authR.user.role == "doctor" && (
                  <Menu.Item key="orders">
                    <Link to="/doctor/payment">
                      <Icon className="icon" type="dollar" />

                      <span className="spn">Invoices</span>
                    </Link>
                  </Menu.Item>
                )}

                {this.props.authR.user.role == "doctor" && (
                  <Menu.Item key="credit">
                    <Link to="/doctor/credit">
                      <Icon className="icon" type="dollar" />

                      <span className="spn">Credits</span>
                    </Link>
                  </Menu.Item>
                )}

                <Menu.Item key="template">
                  <Link to="/doctor/template">
                    <Icon className="icon" type="dollar" />

                    <span className="spn">Templates</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="demograph">
                  <Link to="/doctor/demograph">
                    <Icon
                      style={{ color: "#00ac5c", fontSize: "20px" }}
                      type="dollar"
                    />

                    <span className="spn">Demographics</span>
                  </Link>
                </Menu.Item>

                {this.props.authR.user.role == "doctor" && (
                  <Menu.Item key="orders">
                    <Link to="/doctor/nurse">
                      <Icon className="icon" type="dollar" />

                      <span className="spn">Administrators</span>
                    </Link>
                  </Menu.Item>
                )}

                {/* {this.props.authR.user.role == "doctor" && (
                  <Menu.Item key="orders">
                    <Link to="/doctor/add-payment-method">
                      <Icon className="icon" type="dollar" />

                      <span className="spn">Add Payments Method</span>
                    </Link>
                  </Menu.Item>
                )} */}
              </MenuItemGroup>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ setting, authR }) => {
  const { pathname } = setting;
  return { pathname, authR };
};
export default connect(mapStateToProps)(SidebarContent);

/*
        <MenuItemGroup key="current" className="gx-menu-group" title={<IntlMessages id="sidebar.current"/>}>
                <Menu.Item key="current/events">
                  <Link to="/user/us/current/events">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.current.events"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="current/calender">
                  <Link to="/user/us/current/calender">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.current.calender"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="profile" className="gx-menu-group" title={<IntlMessages id="sidebar.profile"/>}>
                <Menu.Item key="profile/MyProfile">
                  <Link to="/user/us/profile/MyProfile">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.profile.myprofile"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="profile/EditProfile">
                  <Link to="/user/us/profile/EditProfile">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.profile.edit"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
      */
