import React, { Component } from "react";
import { Layout, Drawer } from "antd";
import { connect } from "react-redux";
import {
  toggleCollapsedSideNav,
  updateWindowWidth,
} from "appRedux/actions/Setting";
import SidebarContent from "./SidebarContent";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../../constants/ThemeSetting";

const { Sider } = Layout;

export class Sidebar extends Component {
  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }
  render() {
    const { navCollapsed, width, navStyle } = this.props;
    let drawerStyle = "gx-collapsed-sidebar";
    var layout = "";
    var pathname = window.location.hostname;
    if (pathname.includes("medship")) {
      layout = "gx-layout-sider-med";
    } else if (pathname.includes("femmegen")) {
      layout = "gx-layout-sider-pem";
    } else if (pathname.includes("powderm")) {
      layout = "gx-layout-sider-pow";
    } else {
      layout = "gx-layout-sider-med";
    }

    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = "gx-custom-sidebar";
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "gx-collapsed-sidebar";
    }
    if (
      (navStyle === NAV_STYLE_FIXED ||
        navStyle === NAV_STYLE_MINI_SIDEBAR ||
        navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
      width < TAB_SIZE
    ) {
      drawerStyle = "gx-collapsed-sidebar";
    }
    return (
      <Sider
        className={`gx-app-sidebar ${drawerStyle} ${layout}`}
        trigger={null}
        collapsed={
          width < TAB_SIZE
            ? false
            : navStyle === NAV_STYLE_MINI_SIDEBAR ||
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
        }
        theme={"dark"}
        collapsible
      >
        {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
          <Drawer
            className={`gx-drawer-sidebar gx-drawer-sidebar-dark `}
            placement="left"
            closable={false}
            onClose={this.onToggleCollapsedNav.bind(this)}
            visible={navCollapsed}
          >
            <SidebarContent />
          </Drawer>
        ) : (
          <SidebarContent />
        )}
      </Sider>
    );
  }
}

const mapStateToProps = ({ setting }) => {
  const { navCollapsed, width, locale, navStyle } = setting;
  return { navCollapsed, width, locale, navStyle };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  updateWindowWidth,
})(Sidebar);
