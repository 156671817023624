// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";

export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOADING = "USER_LOADING";

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_CONTACTS = "GET_CONTACTS";
export const UPDATE_USER = "UPDATE_USER";

export const GET_EVENTS = "GET_EVENTS";
export const GET_FILES = "GET_FILES";
export const GET_WORKPLACES = "GET_WORKPLACES";
export const GET_NEWS = "GET_NEWS";
export const USER_DASHBOARD = "USER_DASHBOARD";
export const GET_M_WORKPLACES_BYEVENT = "GET_M_WORKPLACES_BYEVENT";
export const GET_WORKPLACES_BYEVENT = "GET_WORKPLACES_BYEVENT";
export const CREATE_USER = "CREATE_USER";
export const DATA_SET = "DATA_SET";
export const ADD_NEWS = "ADD_NEWS";
export const GET_EVENT = "GET_EVENT";
export const COM_EVENT = "COM_EVENT";
export const ADMIN_DASHBOARD = "ADMIN_DASHBOARD";
export const POST_EVENT = "POST_EVENT";
export const GET_SUB_USER = "GET_SUB_USER";
export const GET_SUB_EV = "GET_SUB_EV";
export const POST_SUB = "POST_SUB";
export const DEL_SUB = "DEL_SUB";
export const GET_REP = "GET_REP";

export const POST_WORKPLACE = "POST_WORKPLACE";

export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_SALEREP = "CREATE_SALEREP";
export const CREATE_DOCTOR = "CREATE_DOCTOR";

export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMIN = "GET_ADMIN";

export const GET_SALEREPS = "GET_SALEREPS";
export const GET_SALEREP = "GET_SALEREP";

export const GET_DOCTORS = "GET_DOCTORS";
export const GET_DOCTOR = "GET_DOCTOR";

export const ALL_QA = "ALL_QA";

export const ADD_VERIFICATION_FILTER = "ADD_VERIFICATION_FILTER";
export const ADD_ORDERS_FILTER = "ADD_ORDERS_FILTER";
export const ADD_DEMO_GRAPHS_FILTER = "ADD_DEMO_GRAPHS_FILTER";
export const ADD_QA_FILTER = "ADD_QA_FILTER";
