import {
  GET_USER,
  GET_USERS,
  GET_CONTACTS,
  UPDATE_USER,
  CREATE_USER,
  ALL_QA,
} from "../../constants/ActionTypes";
const initialState = {
  users: [],
  contacts: [],
  user: null,
  createduser: null,
  allQA: [],
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case CREATE_USER:
      return {
        ...state,
        createduser: payload,
      };
    case GET_CONTACTS: {
      return {
        ...state,
        contacts: payload,
      };
    }
    case ALL_QA: {
      console.log("rrrrrrr", {
        ...state,
        allQA: payload,
      });
      return {
        ...state,
        allQA: payload,
      };
    }
    default:
      return state;
  }
}
