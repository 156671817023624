import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./util/setAuthToken";
import { setCurrentUser, logoutUser } from "./appRedux/actions/AuthActions";

import "assets/vendors/style";
import "styles/wieldy.less";

import configureStore, { history } from "./appRedux/store";

import SaleApp from "./containers/SaleApp/App/index";
import AdminApp from "./containers/AdminApp/App/index";
import DoctorApp from "./containers/DoctorApp/App/index";
import PracticeApp from "./containers/PracticeApp/App/index";

import TwoFactorDoctor from "./util/TwoFactorDoctor";
import TwoFactorPractice from "./util/TwoFactorPractice";
import TwoFactorSale from "./util/TwoFactorSale";
import TwoFactorRoute from "./util/TwoFactorRoute";
import TwoFactorAuth from "./components/TwoFactorAuth";
import SignIn from "./containers/SignIn";
import Forgot from "./containers/Forgot";
import Reset from "./containers/Reset";
import Pay from "./containers/Pay";
import Timer from "./Timer";
import axios from "axios";
import { message } from "antd";
import { getUser } from "./appRedux/actions/users";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log(err);
    const { status, data } = err.response;
    if (status === 403 && data.logout === true && data.code === 403) {
      message.error(data.error);
      store.dispatch(logoutUser());
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export const store = configureStore();
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  store.dispatch(getUser());
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    //  window.location.href = "./login";
  }
}
const NextApp = () => {
  useEffect(() => {
    // Get the current URL

    var pathname = window.location.hostname;

    // Import CSS file based on URL condition
    if (pathname.includes("medship")) {
      import("./med.css").then(() => {
        console.log("Medship styles loaded");
      });
    } else if (pathname.includes("femmegen")) {
      import("./fem.css").then(() => {
        console.log("Medhip styles loaded");
      });
    } else if (pathname.includes("powderm")) {
      import("./pow.css").then(() => {
        console.log("Medpip styles loaded");
      });
    }
  }, []);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/login" component={SignIn} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/payment" component={Pay} />
          <Route path="/reset/:token" component={Reset} />
          <Route path="/two-factor-auth" component={TwoFactorAuth} />

          <Switch>
            <TwoFactorSale path="/sale" component={SaleApp} />
            <TwoFactorDoctor path="/doctor" component={DoctorApp} />
            <TwoFactorPractice path="/practice" component={PracticeApp} />
            <TwoFactorRoute path="/admin" component={AdminApp} />
          </Switch>
        </div>
        <Timer />
      </ConnectedRouter>
    </Provider>
  );
};

export default NextApp;
