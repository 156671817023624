import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Input, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import { verifyTwoFactorAuth } from "../../appRedux/actions/AuthActions"; // Correct import path
import PropTypes from "prop-types";
import logo from "../../assets/logo.jpg";
import { useHistory, useLocation } from "react-router-dom";
const { Title, Paragraph } = Typography;

import jwtDecode from "jwt-decode";

const TwoFactorAuth = ({ verifyTwoFactorAuth }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [tokenValue, setTokenValue] = useState();
  const history = useHistory();
  const location = useLocation();
  const inputs = useRef([]);

  // Function to extract query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const email = useQuery().get("email");

  useEffect(() => {
    console.log(
      'localStorage.getItem("jwtToken")',
      localStorage.getItem("jwtToken")
    );
    // Check if jwtToken is available in localStorage
    if (!localStorage.getItem("jwtToken")) {
      history.push("/login");
    } else {
      // Automatically focus the first input box when the component mounts
      inputs.current[0].focus();
    }

    decodeToken();
  }, [history]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return false;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus on next input
    if (value !== "" && index < 5) {
      inputs.current[index + 1].focus();
    }

    // Automatically submit the form when all six digits are entered
    if (index === 5 && value !== "") {
      verifyTwoFactorAuth({ code: newOtp.join(""), email }, history);
    }
  };

  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text").trim();
    if (pasteData.length === 6 && !isNaN(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);

      // Automatically submit the form after pasting the OTP
      verifyTwoFactorAuth({ code: newOtp.join(""), email }, history);
    }
  };

  function decodeToken() {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("Decoded Token:", decodedToken);
        setTokenValue(decodedToken);
        // return decodedToken;
      } catch (error) {
        console.error("Invalid token:", error);
        return null;
      }
    } else {
      console.log("No token found in localStorage.");
      return null;
    }
  }

  return (
    <div className="gx-app-login-wrap" style={{ marginTop: "15%" }}>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {window.location.hostname === "portal.femmegen.health" && (
                <img
                  src={
                    "https://s3.us-east-2.wasabisys.com/medshipohio/Static/FemmGen-Yellow-Wings.png"
                  }
                  alt="Neature"
                />
              )}
              {window.location.hostname === "portal.powderm.health" && (
                <img
                  src={
                    "https://s3.us-east-2.wasabisys.com/medshipohio/Static/powderm.jpg"
                  }
                  alt="Neature"
                />
              )}
              {window.location.hostname === "portal.medship.health" && (
                <img src={logo} alt="Neature" />
              )}
            </div>
            <div className="gx-app-logo-wid"></div>
          </div>

          <div className="gx-app-login-content">
            <Title level={5}>Enter the 6-Digit Code</Title>
            <Paragraph>
              Please check your {tokenValue?.twoFAMethod} for the OTP.
            </Paragraph>
            <Form className="gx-signin-form gx-form-row0">
              <Form.Item>
                <Row gutter={16}>
                  {otp.map((data, index) => (
                    <Col span={4} key={index}>
                      <Input
                        type="text"
                        maxLength="1"
                        value={data}
                        ref={(el) => (inputs.current[index] = el)}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onFocus={(e) => e.target.select()}
                        onPaste={handlePaste}
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                          fontSize: "24px",
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginTop: 20, marginBottom: 20 }}
                  className="gx-mb-0"
                  onClick={() =>
                    verifyTwoFactorAuth({ code: otp.join(""), email }, history)
                  }
                >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

TwoFactorAuth.propTypes = {
  verifyTwoFactorAuth: PropTypes.func.isRequired,
};

export default connect(null, { verifyTwoFactorAuth })(TwoFactorAuth);
