import React from "react";

import axios from "axios";
import {
  Button,
  Input,
  message,
  Modal,
  Row,
  Col,
  DatePicker,
  Radio,
} from "antd";
import config from "../config";
import Inv from "./Inv";
import Auxiliary from "util/Auxiliary";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

var inv = {};

export default class App extends React.Component {
  state = {
    modal: false,
    inv: {},
    date: "",
    number: "",
    total: "",
    loading: true,
    type: "",
    routing: "",
    accountnumber: "",
    name: "",
    disable: false,
    description: "",
  };

  handleSearch = async () => {
    var { total, date, number } = this.state;
    if (total != "" && date != "" && number != "") {
      console.log(this.state);
      const res = await axios
        .get(`${config.API_URL}/public/${number}/${date}/${total}`)
        .then((res) => {
          console.log(res);
          if (res.data.data != null) {
            this.setState({ inv: res.data.data });
            setTimeout(() => {
              this.setState({ loading: false });
            }, 500);
            //this.setState({loading:false})
          } else {
            message.error("No Data Found");
            this.setState({ inv: {}, loading: true });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      message.error("Please fill the Detail");
    }
  };

  handlePay = async () => {
    console.log("enter");
    var { accountnumber, type, routing, name } = this.state;

    if (accountnumber != "" && type != "" && routing != "" && name != "") {
      this.setState({ disable: true });
      var data = {
        accountnumber,
        type,
        name,
        routing,
        total: this.state.inv.total,
        inv: this.state.inv._id,
        description: this.state.description,
      };
      const res = await axios
        .post(`${config.API_URL}/public/amount`, data)
        .then((res) => {
          console.log(res);
          this.setState({ modal: false });
          this.setState({ disable: false });
        })
        .catch((error) => {
          message.success("Your Invoice is Paid");
          console.log(error.message);
        });
    } else {
      message.error("Please fill the Detail");
    }
  };

  onchanged = (e) => (input) => {
    if (e == "date") {
      this.setState({ [e]: input ? input.format("YYYY-MM-DD") : input });
    } else {
      this.setState({ [e]: input.target.value });
    }
    console.log(e, input);
  };
  render() {
    return (
      <Auxiliary>
        <div style={{ paddingTop: "2%", paddingLeft: "10%" }}>
          <img style={{ width: 100 }} alt="" src={require("assets/logo.jpg")} />
          <div>
            <Row gutter={[16, 20]}>
              <Col sm={7} xs={24}>
                <Row style={{ paddingTop: "10%" }}>
                  <Col span={24}>
                    <DatePicker
                      style={{ width: "280px" }}
                      onChange={this.onchanged("date")}
                      placeholder="Invoice Date"
                    />
                  </Col>
                  <Col span={24}>
                    <Input
                      style={{ marginTop: "20px", width: "280px" }}
                      type="number"
                      onChange={this.onchanged("number")}
                      placeholder="Invoice Number"
                    />
                  </Col>
                  <Col span={24}>
                    <Input
                      style={{ marginTop: "20px", width: "280px" }}
                      type="number"
                      onChange={this.onchanged("total")}
                      placeholder="Invoice Amount"
                    />
                  </Col>
                  <Col span={24}>
                    <Button
                      style={{ marginTop: "20px" }}
                      onClick={(e) => this.handleSearch()}
                    >
                      Search
                    </Button>

                    {this.state.loading == false && (
                      <Button
                        style={{ marginTop: "20px", marginLeft: "50px" }}
                        onClick={(e) => this.setState({ modal: true })}
                      >
                        Pay{" "}
                      </Button>
                    )}
                  </Col>
                  <Modal
                    open={this.state.modal}
                    onCancel={(e) => this.setState({ modal: false })}
                    footer={[
                      <Button
                        key="submit"
                        disabled={this.state.disable}
                        style={{ backgroundColor: "#f11823", color: "white" }}
                        onClick={(e) => this.handlePay()}
                      >
                        Pay{" "}
                        {this.state.inv.total &&
                          this.state.inv.total.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </Button>,
                    ]}
                  >
                    <Row gutter={[16, 20]}>
                      <Col xs={24} sm={8}>
                        <label
                          style={{
                            fontFamily: "unset",
                            textTransform: "uppercase",
                            fontSize: "medium",
                          }}
                        >
                          Account Number
                        </label>
                      </Col>
                      <Col xs={24} sm={16}>
                        <Input
                          className="w-full shadow-inner p-4 border-0"
                          onChange={this.onchanged("accountnumber")}
                          name="accountnumber"
                          placeholder="Account Number"
                        />
                      </Col>
                    </Row>

                    <Row gutter={[16, 20]}>
                      <Col xs={24} sm={8}>
                        <label
                          style={{
                            fontFamily: "unset",
                            textTransform: "uppercase",
                            fontSize: "medium",
                          }}
                        >
                          Routing Number
                        </label>
                      </Col>
                      <Col xs={24} sm={16}>
                        <Input
                          className="w-full shadow-inner p-4 border-0"
                          onChange={this.onchanged("routing")}
                          name="routing"
                          placeholder="Routing Number"
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 20]}>
                      <Col xs={24} sm={8}>
                        <label
                          style={{
                            fontFamily: "unset",
                            textTransform: "uppercase",
                            fontSize: "medium",
                          }}
                        >
                          Account holder
                        </label>
                      </Col>
                      <Col xs={24} sm={16}>
                        <Input
                          className="w-full shadow-inner p-4 border-0"
                          onChange={this.onchanged("name")}
                          name="name"
                          placeholder="Name"
                        />
                      </Col>
                    </Row>

                    <Row gutter={[16, 20]}>
                      <Col xs={24} sm={8}>
                        <label
                          style={{
                            fontFamily: "unset",
                            textTransform: "uppercase",
                            fontSize: "medium",
                          }}
                        >
                          Description
                        </label>
                      </Col>
                      <Col xs={24} sm={16}>
                        <Input.TextArea
                          className="w-full shadow-inner p-4 border-0"
                          onChange={this.onchanged("description")}
                          name="description"
                          placeholder="Description"
                        />
                      </Col>
                    </Row>

                    <Row gutter={[16, 20]}>
                      <Col xs={24} sm={8}>
                        <label
                          style={{
                            fontFamily: "unset",
                            textTransform: "uppercase",
                            fontSize: "medium",
                          }}
                        >
                          Account type
                        </label>
                      </Col>
                      <Col xs={24} sm={16}>
                        <RadioGroup
                          name="warehouse"
                          onChange={this.onchanged("type")}
                        >
                          <RadioButton value="checking">Checking</RadioButton>
                          <RadioButton value="saving">Saving</RadioButton>
                        </RadioGroup>
                      </Col>
                    </Row>
                  </Modal>
                </Row>
              </Col>
              <Col sm={2}></Col>
              <Col sm={15} xs={24}>
                {this.state.loading == false && <Inv order={this.state.inv} />}
              </Col>
            </Row>
          </div>
        </div>
      </Auxiliary>
    );
  }
}
