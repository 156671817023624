import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  render() {
    const { pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={"gx-sidebar-notifications "}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              className="mnu"
              mode="inline"
            >
              <Menu.Item key="dashboard">
                <Link to="/sale/dashboard">
                  <i
                    style={{ color: "#00ac5c" }}
                    className="icon icon-widgets"
                  />
                  <span style={{ color: "white" }}>Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="report">
                <Link to="/sale/detailreport">
                  <i
                    style={{ color: "#00ac5c" }}
                    className="icon icon-widgets"
                  />
                  <span style={{ color: "white" }}>Practice / Doc Report</span>
                </Link>

          
              </Menu.Item>

              <MenuItemGroup
                key="users"
                className="gx-menu-group"
                title={<span style={{ color: "white" }}>Doctors</span>}
              >
                <Menu.Item key="sale/doctor">
                  <Link to="/sale/doctors">
                    <i
                      style={{ color: "#00ac5c" }}
                      className="icon icon-avatar"
                    />
                    <span style={{ color: "white" }}>Doctors</span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>

              <Menu.Item key="dashboard">
                <Link to="/sale/config">
                  <i
                    style={{ color: "#00ac5c" }}
                    className="icon icon-setting"
                  />
                  <span style={{ color: "white" }}>Config</span>
                </Link>
              </Menu.Item>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ setting }) => {
  const { pathname } = setting;
  return { pathname };
};
export default connect(mapStateToProps)(SidebarContent);

/*
              <MenuItemGroup key="current" className="gx-menu-group" title={<IntlMessages id="sidebar.current"/>}>
                <Menu.Item key="current/events">
                  <Link to="/user/us/current/events">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.current.events"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="current/calender">
                  <Link to="/user/us/current/calender">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.current.calender"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="profile" className="gx-menu-group" title={<IntlMessages id="sidebar.profile"/>}>
                <Menu.Item key="profile/MyProfile">
                  <Link to="/user/us/profile/MyProfile">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.profile.myprofile"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="profile/EditProfile">
                  <Link to="/user/us/profile/EditProfile">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.profile.edit"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
          
*/
