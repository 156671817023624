import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import AdminRoute from "../util/AdminRoute";
//import PrivateRoute from "../util/PrivateRoute"

const App = ({ match, role }) => (
  <div className="gx-main-content-wrapper">
    {console.log(role)}
    {role === "admin" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./AdminRoutes"))}
      />
    ) : role === "doctor" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./doctorRoutes"))}
      />
    ) : role === "practice" ? (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./practiceRoutes"))}
      />
    ) : (
      <Route
        excat
        path={`${match.url}`}
        component={asyncComponent(() => import("./SaleRoutes"))}
      />
    )}
  </div>
);

export default App;
/**
 * 
 *    <Switch>
    <Route excat path={`${match.url}/us`} component={asyncComponent(() => import('./UserRoutes'))}/>
 
 
  
 
  
  
  
       </Switch>
 
 *       

 *    <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}current`} component={asyncComponent(() => import('./Current'))}/>
      <Route path={`${match.url}reporting`} component={asyncComponent(() => import('./Reporting'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))}/>
      <Route path={`${match.url}administration`} component={asyncComponent(() => import('./Administration'))}/>

 */
