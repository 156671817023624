import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCubes,
  faPersonChalkboard,
  faHospital,
  faTruckFast,
  faFilePrescription,
  faFileCirclePlus,
  faGlobe,
  faMap,
  faFileInvoiceDollar,
  faReceipt,
  faHandHoldingDollar,
  faUserDoctor,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  SafetyCertificateOutlined,
  MedicineBoxOutlined,
  LaptopOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  MoneyCollectOutlined,
  CopyOutlined,
  FileOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";

import { Link } from "react-router-dom";
//import {Icon} from "antd"
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import config from "../../../config";
import axios from "axios";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  state = {
    admin: "",
    warehouse: "",
    permissions: [],
  };

  getAdmin = () => {
    const token = localStorage.getItem("token");

    axios.get(`${config.API_URL}/admin/get/me`, {}).then((response) => {
      console.log(response);
      this.setState({
        admin: response.data.data,
      });
      this.setState({
        warehouse: response.data.data.warehouse,
        permissions: response.data.data.permissions,
      });
    });
  };

  componentDidMount() {
    console.log(this.props);
    this.getAdmin();
  }

  render() {
    const { pathname } = this.props;
    const { permissions } = this.state;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={"gx-sidebar-notifications "}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={"dark"}
              style={{ backgroundColor: "#040a07" }}
              mode="inline"
            >
              {permissions?.includes("dashboard") && (
                <Menu.Item key="dashboard">
                  <Link to="/admin/dashboard">
                    <i
                      style={{ color: "#00ac5c", fontSize: "20px" }}
                      className="icon icon-chart"
                    />
                    <span style={{ color: "white" }}>Dashboard</span>
                  </Link>
                </Menu.Item>
              )}
              {permissions?.includes("orderdashboard") && (
                <Menu.Item key="dashboard2">
                  <Link to="/admin/order/orderdashboard">
                    <i
                      style={{ color: "#00ac5c", fontSize: "20px" }}
                      className="icon icon-chart"
                    />
                    <span style={{ color: "white" }}>Order Dashboard</span>
                  </Link>
                </Menu.Item>
              )}
              {permissions?.includes("adminsetting") && (
                <Menu.Item key="commission">
                  <Link to="/admin/adminsetting">
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      style={{
                        color: "#00ac5c",
                        fontSize: "20px",
                        marginRight: "20px",
                      }}
                    />
                    <span style={{ color: "white" }}>Admin Settings</span>
                  </Link>
                </Menu.Item>
              )}
              <SubMenu
                key="util"
                className="gx-menu-group"
                title={
                  <span style={{ color: "white" }}>
                    {" "}
                    <FontAwesomeIcon
                      style={{
                        color: "#00ac5c",
                        fontSize: "20px",
                        marginRight: "20px",
                      }}
                      icon={faScrewdriverWrench}
                    />
                    Utilities
                  </span>
                }
              >
                {permissions?.includes("util") && (
                  <Menu.Item key="admin/util/pricingtool">
                    <Link to="/admin/util/pricingtool">
                      <i
                        className="icon icon-crypto"
                        style={{ color: "#00ac5c", fontSize: "20px" }}
                      />
                      <span style={{ color: "white" }}>Order Pricing</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("util") && (
                  <Menu.Item key="admin/util/blockedAccounts">
                    <Link to="/admin/util/blockedAccounts">
                      <i
                        className="icon icon-crypto"
                        style={{ color: "#00ac5c", fontSize: "20px" }}
                      />
                      <span style={{ color: "white" }}>Blocked Accounts</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("util") && (
                  <Menu.Item key="admin/util/docreport">
                    <Link to="/admin/util/docreport">
                      <span style={{ color: "white" }}>Doctors Report</span>
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
              {permissions?.includes("collection") && (
                <Menu.Item key="collection">
                  <Link to="/admin/collection">
                    <i
                      style={{ color: "#00ac5c", fontSize: "20px" }}
                      className="icon icon-chart"
                    />
                    <span style={{ color: "white" }}>Collection</span>
                  </Link>
                </Menu.Item>
              )}
              <MenuItemGroup
                key="users"
                className="gx-menu-group"
                title={<span style={{ color: "white" }}>Users</span>}
              >
                {permissions?.includes("admins") && (
                  <Menu.Item key="users/admins">
                    <Link to="/admin/users/admins">
                      <SafetyCertificateOutlined
                        style={{ color: "#00ac5c", fontSize: "20px" }}
                      />

                      <span style={{ color: "white" }}>Admins</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("salerep") && (
                  <Menu.Item key="users/salerep">
                    <Link to="/admin/users/salerep">
                      <FontAwesomeIcon
                        icon={faPersonChalkboard}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Sales Reps</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("doctors") && (
                  <Menu.Item key="users/doctors">
                    <Link to="/admin/users/doctors">
                      <FontAwesomeIcon
                        icon={faUserDoctor}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Doctors</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("practice") && (
                  <Menu.Item key="users/practice">
                    <Link to="/admin/users/practice">
                      <FontAwesomeIcon
                        icon={faHospital}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Practices</span>
                    </Link>
                  </Menu.Item>
                )}
              </MenuItemGroup>
              <MenuItemGroup
                key="items"
                className="gx-menu-group"
                title={<span style={{ color: "white" }}>Items</span>}
              >
                {permissions?.includes("items") && (
                  <Menu.Item key="items">
                    <Link to="/admin/items">
                      <FontAwesomeIcon
                        icon={faCubes}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Items</span>
                    </Link>
                  </Menu.Item>
                )}
              </MenuItemGroup>
              {permissions?.includes("shipping") && (
                <MenuItemGroup
                  key="Shipping"
                  className="gx-menu-group"
                  title={<span style={{ color: "white" }}>Shipping</span>}
                >
                  <Menu.Item key="Shipping">
                    <Link to="/admin/shipping">
                      <FontAwesomeIcon
                        icon={faTruckFast}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Shipping</span>
                    </Link>
                  </Menu.Item>
                </MenuItemGroup>
              )}
              <MenuItemGroup
                key="order"
                className="gx-menu-group"
                title={<span style={{ color: "white" }}>Orders</span>}
              >
                {permissions?.includes("demographic") && (
                  <Menu.Item key="demographics">
                    <Link to="/admin/order/demographic">
                      <FontAwesomeIcon
                        icon={faFilePrescription}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Demographics</span>
                    </Link>
                  </Menu.Item>
                )}

                {permissions?.includes("verification") && (
                  <Menu.Item key="verifications">
                    <Link to="/admin/order/verification">
                      <FontAwesomeIcon
                        icon={faFilePrescription}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Verifications</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("verification") && (
                  <Menu.Item key="verifications">
                    <Link to="/admin/order/authorizations">
                      <FontAwesomeIcon
                        icon={faFilePrescription}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Authorizations</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("orders") && (
                  <Menu.Item key="orders">
                    <Link to="/admin/order/orders">
                      <FontAwesomeIcon
                        icon={faFilePrescription}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Orders</span>
                    </Link>
                  </Menu.Item>
                )}

                {permissions?.includes("qa") && (
                  <Menu.Item key="qa">
                    <Link to="/admin/qa">
                      <FontAwesomeIcon
                        icon={faFilePrescription}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Quality Control</span>
                    </Link>
                  </Menu.Item>
                )}

                {permissions?.includes("createorder") && (
                  <Menu.Item key="createorder">
                    <Link to="/admin/order/createorder">
                      <FontAwesomeIcon
                        icon={faFileCirclePlus}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Create Order</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("template") && (
                  <Menu.Item key="template">
                    <Link to="/admin/template">
                      <FontAwesomeIcon
                        icon={faGlobe}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Master Templates</span>
                    </Link>
                  </Menu.Item>
                )}

                {permissions?.includes("template") && (
                  <Menu.Item key="doctemplate">
                    <Link to="/admin/doctemplate">
                      <FontAwesomeIcon
                        icon={faMap}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />

                      <span style={{ color: "white" }}>Templates</span>
                    </Link>
                  </Menu.Item>
                )}
              </MenuItemGroup>
              <MenuItemGroup
                key="report"
                className="gx-menu-group"
                title={<span style={{ color: "white" }}>Reports</span>}
              >
                {permissions?.includes("report") && (
                  <Menu.Item key="repo">
                    <Link to="/admin/util/report">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>EOB/Payment Report</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("sale_Report") && (
                  <Menu.Item key="repo">
                    <Link to="/admin/util/sale_report">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Sales Report</span>
                    </Link>
                  </Menu.Item>
                )}
              </MenuItemGroup>

              <MenuItemGroup
                key="payments"
                className="gx-menu-group"
                title={<span style={{ color: "white" }}>Payments</span>}
              >
                {permissions?.includes("invoice") && (
                  <Menu.Item key="payments">
                    <Link to="/admin/payments">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Invoices</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("credit") && (
                  <Menu.Item key="credits">
                    <Link to="/admin/credits">
                      <FontAwesomeIcon
                        icon={faReceipt}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Credits</span>
                    </Link>
                  </Menu.Item>
                )}
                {permissions?.includes("commission") && (
                  <Menu.Item key="commission">
                    <Link to="/admin/commission">
                      <FontAwesomeIcon
                        icon={faHandHoldingDollar}
                        style={{
                          color: "#00ac5c",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                      <span style={{ color: "white" }}>Commission</span>
                    </Link>
                  </Menu.Item>
                )}
              </MenuItemGroup>
            </Menu>
          </CustomScrollbars>
        </div>
      </>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ setting, authR, users }) => {
  const { pathname } = setting;
  return { pathname, authR, users };
};
export default connect(mapStateToProps)(SidebarContent);

/*
<MenuItemGroup key="current" className="gx-menu-group" title={<IntlMessages id="sidebar.current"/>}>
                <Menu.Item key="current/events">
                  <Link to="/admin/current/events">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.current.events"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="current/calender">
                  <Link to="/admin/current/calender">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.current.calender"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="reporting" className="gx-menu-group" title={<IntlMessages id="sidebar.reporting"/>}>
                <Menu.Item key="reporting/Reports">
                  <Link to="/admin/reporting/Reports">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.reporting.reports"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="reporting/Contacts">
                  <Link to="/admin/reporting/Contacts">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.reporting.contacts"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="profile" className="gx-menu-group" title={<IntlMessages id="sidebar.profile"/>}>
                <Menu.Item key="profile/MyProfile">
                  <Link to="/admin/profile/MyProfile">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.profile.myprofile"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="profile/EditProfile">
                  <Link to="/admin/profile/EditProfile">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.profile.edit"/>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup key="administration" className="gx-menu-group" title={<IntlMessages id="sidebar.administration"/>}>
              <SubMenu key="Evnets" className={"gx-no-header-submenu-popup"} title={
                  <span>
                  <i className="icon icon-all-contacts"/>
                   <IntlMessages id="sidebar.administration.events"/>
              </span>}>
              <Menu.Item key="administration/Events/Event">
                  <Link to="/admin/administration/Events/Event">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.administration.events"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="administration/Events/createEvent">
                  <Link to="/admin/administration/Events/createEvent">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.administration.events.createevent"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="administration/Events/ManageEvent">
                  <Link to="/admin/administration/Events/ManageEvent">
                    <i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.administration.events.manageevent"/>
                  </Link>
                </Menu.Item>

                </SubMenu>

                <SubMenu key="Users" className={"gx-no-header-submenu-popup"} title={
                  <span>
                  <i className="icon icon-all-contacts"/>
                   <IntlMessages id="sidebar.administration.users"/>
              </span>}>
              <Menu.Item key="administration/Users/AllUsers">
                  <Link to="/admin/administration/Users/AllUsers">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.administration.users"/>
                  </Link>
                </Menu.Item><Menu.Item key="administration/Users/CreateUser">
                  <Link to="/admin/administration/Users/CreateUser">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.administration.users.createuser"/>
                  </Link>
                </Menu.Item>
                </SubMenu>
                
                <Menu.Item key="administration/Files">
                  <Link to="/admin/administration/Files">
                    <i className="icon icon-avatar -flex-column-reverse"/>
                    <IntlMessages id="sidebar.administration.files"/>
                  </Link>
                </Menu.Item>

              </MenuItemGroup>
              <MenuItemGroup key="setting" className="gx-menu-group" title={<IntlMessages id="sidebar.setting"/>}>
                <Menu.Item key="admin/setting">
                  <Link to="/admin/setting/Contactfix">
                    <i className="icon icon-setting"/>
                    <IntlMessages id="sidebar.setting.fixtation"/>
                  </Link>
                </Menu.Item>
                <Menu.Item key="admin/setting/test">
                  <Link to="/admin/setting/testRoute">
                    <i className="icon icon-setting"/>
                    <IntlMessages id="sidebar.setting.fixtation"/>
                  </Link>
                </Menu.Item>
                </MenuItemGroup>
*/
