import {SWITCH_LANGUAGE,TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "constants/ActionTypes";
import {
  NAV_STYLE,
  NAV_STYLE_FIXED
  } from "../../constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  width: window.innerWidth,
  navStyle: NAV_STYLE_FIXED,
  pathname: '/',
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };
      case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
      case NAV_STYLE:
        return {
          ...state,
          navStyle: action.navStyle
        };
    default:
      return state;
  }
};

export default settings;
