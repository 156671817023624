import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AuthReducer from "./AuthReducer";
import users from "./users";
import Setting from "./Settings";
import QueryFilter from "./queryFilters";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    authR: AuthReducer,
    users: users,
    setting: Setting,
    queryFilter: QueryFilter,
  });

export default reducers;
