import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "assets/images/white-logo-trans.png";

import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";

class SidebarLogo extends Component {
  render() {
    const { width, navCollapsed } = this.props;
    let { navStyle } = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }

    return (
      <div className="gx-layout-sider-header">
        {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
          <div className="gx-linebar">
            <i
              className={`gx-icon-btn icon icon-${
                navStyle === NAV_STYLE_MINI_SIDEBAR
                  ? "menu-unfold"
                  : "menu-fold"
              } }`}
              onClick={() => {
                if (navStyle === NAV_STYLE_DRAWER) {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                } else if (navStyle === NAV_STYLE_FIXED) {
                  this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR);
                } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                } else {
                  this.props.onNavStyleChange(NAV_STYLE_FIXED);
                }
              }}
            />
          </div>
        ) : null}

        <Link to="/admin/dashboard" className="gx-site-logo">
          {window.location.hostname == "portal.femmegen.health" && (
            <img
              style={{ width: 100 }}
              src={
                "https://s3.us-east-2.wasabisys.com/medshipohio/Static/FemmGen-Yellow-Wings.png"
              }
              alt="Neature"
            />
          )}
          {window.location.hostname == "portal.powderm.health" && (
            <img
              style={{ width: 100 }}
              src={
                "https://s3.us-east-2.wasabisys.com/medshipohio/Static/powderm.jpg"
              }
              alt="Neature"
            />
          )}
          {window.location.hostname == "portal.medship.health" && (
            <img style={{ width: 100 }} src={logo} alt="Neature" />
          )}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ setting }) => {
  const { navStyle, themeType, width, navCollapsed } = setting;
  return { navStyle, themeType, width, navCollapsed };
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav,
})(SidebarLogo);
